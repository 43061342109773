<div class="all" [ngClass]="[this.multiplosTrechos == 'multiplos-trechos' ? 'multiplosTrechos' : 'aereo-card']">
    <div [ngClass]="[this.multiplosTrechos == 'ida' ? 'bodyContentIda' : 'bodyContent']">
        <div class="row w-100">
            <div class="col-12 d-flex">
                <h5>
                    D:<span class="bg-primary" style="margin-right: 2px; color: #FFFFFF;"> {{bloqueio.ExtraIntegracao2.Disponivel}} </span>
                    R:<span class="bg-warning" style="margin-right: 2px; color: #FFFFFF;"> {{bloqueio.ExtraIntegracao2.Reservado}} </span>
                    E:<span class="bg-dark" style="margin-right: 2px; color: #FFFFFF;"> {{bloqueio.ExtraIntegracao2.Emitido}} </span>
                    <img style="max-height: 20px" src="assets/img/cias/{{bloqueio.Fornecedor}}.png" />
                </h5>
            </div>
            <div class="col-12">
                <div *ngFor="let seguimento of bloqueio.Seguimentos; let i = index" class="seguimento">
                    <div class="ida">
                        <div class='row jc-sb'>
                            <div class='col-12'>
                                <h5>{{"Trecho" | translate}} 1 <span style="font-weight: normal;">({{'Duração' | translate}}: {{bloqueio.TempoPorSeguimento[0]}})</span></h5>
                            </div>
                        </div>
                        <div *ngFor="let voo of seguimento.Voos; let k = index">
                            <div class='row jc-se'>
                                <div class='col-2 as-c'>
                                    <img src="assets/img/cias/{{voo.CiaMarketing}}.png" alt="{{voo.CiaMarketing}}"
                                        title="{{bloqueio.CiaValidadora}}" />
                                </div>
                                <div class='col-2'>
                                    <p
                                        *ngIf="k == 0 || (k > 0 && voo.AeroportoPartida == seguimento.Voos[k - 1].AeroportoChegada)">
                                        {{ voo.AeroportoPartida }}</p>
                                    <p *ngIf="k > 0 && voo.AeroportoPartida != seguimento.Voos[k - 1].AeroportoChegada"
                                        style="color:red">{{ voo.AeroportoPartida }}*</p>
                                    <p style="font-size: 13px;font-weight: bold;">{{ voo.DataPartida | date :
                                        'dd/MM/yyyy HH:mm'}}</p>
                                </div>
                                <div class='col-3 text-center'>
                                    <div class='col voo-detalhes'>
                                        <!-- <p>{{voo.Duracao.substring(0,5)}}</p> -->
                                        <app-trecho-simbol></app-trecho-simbol>
                                    </div>
                                </div>
                                <div class='col-2'>
                                    <p>{{ voo.AeroportoChegada }}</p>
                                    <p style="font-size: 13px;font-weight: bold;">{{voo.DataChegada | date : 'dd/MM/yyyy HH:mm'}}</p>
                                </div>
                            </div>

                            <div class='row'>
                                <div class='col'>
                                    <h6>{{"Voo" | translate}} N°{{voo.NumeroVoo}} | {{"Classe" | translate}}:
                                        {{voo.ClasseTarifaria}} | {{"Bagagem" | translate}}:
                                        {{seguimento.BagagemQuantidade}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="bloqueio.ExtraIntegracao2.Disponivel < totalPax">
                <p>{{'Quantidade de pax pedido maior que disponibilidade do bloqueio' | translate}}.</p>
            </div>
        </div>
        <div class="preco">
            <div class='row text-center' *ngIf="user.EmpresaId != (undefined || null)">
                <div class='col as-c'>
                    <h5>{{"Fornecedor" | translate}}</h5>
                    <img src="assets/img/cias/{{ bloqueio.Fornecedor }}.png" alt="{{ bloqueio.Fornecedor }}"
                        title="{{ bloqueio.Fornecedor }}" />
                </div>
            </div>

            <div class='row '>
                <div class='col text-center'>
                    <h5> {{bloqueio.MoedaListagem.Simbolo}} {{bloqueio.TarifasListagemTotal | currency:'BRL':'':'2.2-2' }}</h5>
                    <p>+ {{'Taxas' | translate}}: {{bloqueio.MoedaListagem.Simbolo}} {{bloqueio.TaxasListagemTotal | currency:'BRL':'':'2.2-2'}}</p>
                    <p *ngIf="bloqueio.TaxasDUListagemTotal>0">+ {{'RC' | translate}}:
                        {{bloqueio.MoedaListagem.Simbolo}} {{bloqueio.TaxasDUListagemTotal | currency:'BRL':'':'2.2-2'}}</p>
                </div>
            </div>

            <div class='row' *ngIf="!Laminas">
                <div class='col text-center'>
                    <label class="btn btn-success" *ngIf="!bloqueio.selecionado">
                        <input type="checkbox" class="checked-btn" (click)="selecionou(bloqueio)" /><span>{{'Adicionar' | translate}}</span>
                    </label>
                    <label class="btn btn-outline-success" *ngIf="bloqueio.selecionado">
                        <input type="checkbox" class="checked-btn" (click)="removeu(bloqueio)" /><span>{{'Remover' | translate}}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>