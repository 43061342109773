import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Aereo, Usuario } from 'src/app/model';

@Component({
  selector: 'app-bloqueio',
  templateUrl: './bloqueio.component.html',
  styleUrls: ['./bloqueio.component.scss']
})
export class BloqueioComponent implements OnInit {

  constructor() { }

  @Input() bloqueio: Aereo;
  @Input() usuario: Usuario = new Usuario();
  @Input() Laminas: boolean = false;
  @Input() busca: any;

  @Output() selecionouAereo = new EventEmitter<any>();
  @Output() removeuAereo = new EventEmitter<any>();

  totalPax: number = 0;
  multiplosTrechos: any;
  user: any;

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('loggedUser'));
    if(!this.Laminas){
      this.multiTrechos();
    }

    this.totalPax = this.busca.Adt + this.busca.Chd + this.busca.Inf;
  }

  multiTrechos() {
    const multiplosTrechos = JSON.parse(sessionStorage.getItem('busca'))
    this.multiplosTrechos = multiplosTrechos.tipoBuscaAereo;
    (this.multiplosTrechos == 'multiplos-trechos' ? true : false);
  }

  selecionou(bloqueio: any) {
    this.selecionouAereo.emit(bloqueio);
  }

  removeu(bloqueio: any) {
    this.removeuAereo.emit(bloqueio);
  }

}
